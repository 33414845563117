const routes = [
  {
    path: "",
    name: "dashboard",
    meta: { requiredPermissions: [] },
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/DashboardOne.vue"
      ),
  },
  {
    path: "/administracao",
    name: "administracao",
    meta: { requiredPermissions: [] },
    component: () =>
      import(/* webpackChunkName: "axios" */ "@/view/crud/axios/Index.vue"),
    children: [
      {
        path: "usuarios",
        name: "usuarios-index",
        meta: {
          requiredPermissions: [
            "Cadastros.Usuarios.Manipular",
            "Cadastros.Usuarios.Visualizar",
            "Cadastros.Usuarios.Inserir",
            "Cadastros.Usuarios.Editar",
            "Cadastros.Usuarios.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/administracao/usuarios/Index.vue"
          ),
      },
      {
        path: "usuarios/:id",
        name: "usuarios-edit",
        meta: {
          requiredPermissions: [
            "Cadastros.Usuarios.Manipular",
            "Cadastros.Usuarios.Visualizar",
            "Cadastros.Usuarios.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/usuarios/Form.vue"
          ),
      },
      {
        path: "usuarios/new",
        name: "usuarios-create",
        meta: {
          requiredPermissions: [
            "Cadastros.Usuarios.Manipular",
            "Cadastros.Usuarios.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/usuarios/Form.vue"
          ),
      },

      {
        path: "perfis",
        name: "perfis-index",
        meta: {
          requiredPermissions: [
            "Common.Perfis.Manipular",
            "Common.Perfis.Visualizar",
            "Common.Perfis.Inserir",
            "Common.Perfis.Editar",
            "Common.Perfis.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/administracao/perfis/Index.vue"
          ),
      },
      {
        path: "perfis/:id",
        name: "perfis-edit",
        meta: {
          requiredPermissions: [
            "Common.Perfis.Manipular",
            "Common.Perfis.Visualizar",
            "Common.Perfis.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/perfis/Form.vue"
          ),
      },
      {
        path: "perfis/new",
        name: "perfis-create",
        meta: {
          requiredPermissions: [
            "Common.Perfis.Manipular",
            "Common.Perfis.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/perfis/Form.vue"
          ),
      },

      {
        path: "empresas",
        name: "empresas-index",
        meta: {
          requiredPermissions: [
            "Cadastros.Empresas.Manipular",
            "Cadastros.Empresas.Visualizar",
            "Cadastros.Empresas.Inserir",
            "Cadastros.Empresas.Editar",
            "Cadastros.Empresas.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/administracao/empresas/Index.vue"
          ),
      },
      {
        path: "empresas/:id",
        name: "empresas-edit",
        meta: {
          requiredPermissions: [
            "Cadastros.Empresas.Manipular",
            "Cadastros.Empresas.Visualizar",
            "Cadastros.Empresas.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/empresas/Form.vue"
          ),
      },
      {
        path: "empresas/new",
        name: "empresas-create",
        meta: {
          requiredPermissions: [
            "Cadastros.Empresas.Manipular",
            "Cadastros.Empresas.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/administracao/empresas/Form.vue"
          ),
      },

      {
        path: "log-alteracoes",
        name: "log-alteracoes-index",
        meta: { requiredPermissions: ["Super Admin"] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/administracao/log-alteracoes/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/configuracoes",
    name: "configuracoes",
    meta: { requiredPermissions: [] },
    component: () =>
      import(/* webpackChunkName: "axios" */ "@/view/crud/axios/Index.vue"),
    children: [
      {
        path: "integradores",
        name: "integradores-index",
        meta: {
          requiredPermissions: [
            "Common.Integradores.Manipular",
            "Common.Integradores.Visualizar",
            "Common.Integradores.Inserir",
            "Common.Integradores.Editar",
            "Common.Integradores.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/integradores/Index.vue"
          ),
      },
      {
        path: "integradores/:id",
        name: "integradores-edit",
        meta: {
          requiredPermissions: [
            "Common.Integradores.Manipular",
            "Common.Integradores.Visualizar",
            "Common.Integradores.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/integradores/Form.vue"
          ),
      },
      {
        path: "integradores/new",
        name: "integradores-create",
        meta: {
          requiredPermissions: [
            "Common.Integradores.Manipular",
            "Common.Integradores.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/integradores/Form.vue"
          ),
      },
      {
        path: "menus",
        name: "menus-index",
        meta: {
          requiredPermissions: [
            "Common.Menus.Manipular",
            "Common.Menus.Visualizar",
            "Common.Menus.Inserir",
            "Common.Menus.Editar",
            "Common.Menus.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/menus/Index.vue"
          ),
      },
      {
        path: "menus/:id",
        name: "menus-edit",
        meta: {
          requiredPermissions: [
            "Common.Menus.Manipular",
            "Common.Menus.Visualizar",
            "Common.Menus.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/menus/Form.vue"
          ),
      },
      {
        path: "menus/new",
        name: "menus-create",
        meta: {
          requiredPermissions: [
            "Common.Menus.Manipular",
            "Common.Menus.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/menus/Form.vue"
          ),
      },

      {
        path: "parametros",
        name: "parametros-index",
        meta: {
          requiredPermissions: [
            "Common.Parametros.Manipular",
            "Common.Parametros.Visualizar",
            "Common.Parametros.Inserir",
            "Common.Parametros.Editar",
            "Common.Parametros.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/parametros/Index.vue"
          ),
      },
      {
        path: "parametros/:id",
        name: "parametros-edit",
        meta: {
          requiredPermissions: [
            "Common.Parametros.Manipular",
            "Common.Parametros.Visualizar",
            "Common.Parametros.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/parametros/Form.vue"
          ),
      },
      {
        path: "parametros/new",
        name: "parametros-create",
        meta: {
          requiredPermissions: [
            "Common.Parametros.Manipular",
            "Common.Parametros.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/configuracoes/parametros/Form.vue"
          ),
      },

      {
        path: "permissoes",
        name: "permissoes-index",
        meta: { requiredPermissions: ["Super Admin"] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/permissoes/Index.vue"
          ),
      },
      {
        path: "paises",
        name: "paises-index",
        meta: { requiredPermissions: [] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/paises/Index.vue"
          ),
      },
      {
        path: "estados",
        name: "estados-index",
        meta: { requiredPermissions: [] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/estados/Index.vue"
          ),
      },
      {
        path: "cidades",
        name: "cidades-index",
        meta: { requiredPermissions: [] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/cidades/Index.vue"
          ),
      },
      {
        path: "cambios",
        name: "cambios-index",
        meta: { requiredPermissions: ["Super Admin"] },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/configuracoes/cambios/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/cadastros",
    name: "cadastros",
    meta: { requiredPermissions: [] },
    component: () =>
      import(/* webpackChunkName: "axios" */ "@/view/crud/axios/Index.vue"),
    children: [
      {
        path: "marcas",
        name: "marcas-index",
        meta: {
          requiredPermissions: [
            "Ecommerce.Manipular",
            "Ecommerce.Visualizar",
            "Ecommerce.Editar",
            "Ecommerce.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/marcas/Index.vue"
          ),
      },
      {
        path: "marcas/:id",
        name: "marcas-edit",
        meta: {
          requiredPermissions: [
            "Ecommerce.Manipular",
            "Ecommerce.Visualizar",
            "Ecommerce.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/marcas/Form.vue"
          ),
      },
      {
        path: "marcas/new",
        name: "marcas-create",
        meta: {
          requiredPermissions: [
            "Ecommerce.Manipular",
            "Ecommerce.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/marcas/Form.vue"
          ),
      },
      {
        path: "categorias",
        name: "categorias-index",
        meta: {
          requiredPermissions: [
            "Ecommerce.Categoria.Manipular",
            "Ecommerce.Categoria.Visualizar",
            "Ecommerce.Categoria.Editar",
            "Ecommerce.Categoria.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/categorias/Index.vue"
          ),
      },
      {
        path: "categorias/:id",
        name: "categorias-edit",
        meta: {
          requiredPermissions: [
            "Ecommerce.Categoria.Manipular",
            "Ecommerce.Categoria.Visualizar",
            "Ecommerce.Categoria.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/categorias/Form.vue"
          ),
      },
      {
        path: "categorias/new",
        name: "categorias-create",
        meta: {
          requiredPermissions: [
            "Ecommerce.Categoria.Manipular",
            "Ecommerce.Categoria.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/categorias/Form.vue"
          ),
      },
      {
        path: "departamentos",
        name: "departamentos-index",
        meta: {
          requiredPermissions: [
            "Ecommerce.Departamento.Manipular",
            "Ecommerce.Departamento.Visualizar",
            "Ecommerce.Departamento.Editar",
            "Ecommerce.Departamento.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/departamentos/Index.vue"
          ),
      },
      {
        path: "departamentos/:id",
        name: "departamentos-edit",
        meta: {
          requiredPermissions: [
            "Ecommerce.Departamento.Manipular",
            "Ecommerce.Departamento.Visualizar",
            "Ecommerce.Departamento.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/departamentos/Form.vue"
          ),
      },
      {
        path: "departamentos/new",
        name: "departamentos-create",
        meta: {
          requiredPermissions: [
            "Ecommerce.Departamento.Manipular",
            "Ecommerce.Departamento.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/departamentos/Form.vue"
          ),
      },
      {
        path: "especificacoes",
        name: "especificacoes-index",
        meta: {
          requiredPermissions: [
            "Ecommerce.Especificacao.Manipular",
            "Ecommerce.Especificacao.Visualizar",
            "Ecommerce.Especificacao.Editar",
            "Ecommerce.Especificacao.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/especificacoes/Index.vue"
          ),
      },
      {
        path: "especificacoes/:id",
        name: "especificacoes-edit",
        meta: {
          requiredPermissions: [
            "Ecommerce.Especificacao.Manipular",
            "Ecommerce.Especificacao.Visualizar",
            "Ecommerce.Especificacao.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/especificacoes/Form.vue"
          ),
      },
      {
        path: "especificacoes/new",
        name: "especificacoes-create",
        meta: {
          requiredPermissions: [
            "Ecommerce.Especificacao.Manipular",
            "Ecommerce.Especificacao.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/especificacoes/Form.vue"
          ),
      },
      {
        path: "produtos",
        name: "produtos-index",
        meta: {
          requiredPermissions: [
            "Ecommerce.Produto.Manipular",
            "Ecommerce.Produto.Visualizar",
            "Ecommerce.Produto.Editar",
            "Ecommerce.Produto.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/produtos/Index.vue"
          ),
      },
      {
        path: "produtos/:id",
        name: "produtos-edit",
        meta: {
          requiredPermissions: [
            "Ecommerce.Produto.Manipular",
            "Ecommerce.Produto.Visualizar",
            "Ecommerce.Produto.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/produtos/Form.vue"
          ),
      },
      {
        path: "produtos/new",
        name: "produtos-create",
        meta: {
          requiredPermissions: [
            "Ecommerce.Produto.Manipular",
            "Ecommerce.Produto.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/produtos/Form.vue"
          ),
      },

      {
        path: "funcoes",
        name: "funcoes-index",
        meta: {
          requiredPermissions: [
            "Cadastros.Funcao.Manipular",
            "Cadastros.Funcao.Visualizar",
            "Cadastros.Funcao.Editar",
            "Cadastros.Funcao.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/funcoes/Index.vue"
          ),
      },
      {
        path: "funcoes/:id",
        name: "funcoes-edit",
        meta: {
          requiredPermissions: [
            "Cadastros.Funcao.Manipular",
            "Cadastros.Funcao.Visualizar",
            "Cadastros.Funcao.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/funcoes/Form.vue"
          ),
      },
      {
        path: "funcoes/new",
        name: "funcoes-create",
        meta: {
          requiredPermissions: [
            "Cadastros.Funcao.Manipular",
            "Cadastros.Funcao.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/funcoes/Form.vue"
          ),
      },


      {
        path: "colaboradores",
        name: "colaboradores-index",
        meta: {
          requiredPermissions: [
            "Cadastros.Colaborador.Manipular",
            "Cadastros.Colaborador.Visualizar",
            "Cadastros.Colaborador.Editar",
            "Cadastros.Colaborador.Deletar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-view" */ "@/view/cadastros/colaboradores/Index.vue"
          ),
      },
      {
        path: "colaboradores/:id",
        name: "colaboradores-edit",
        meta: {
          requiredPermissions: [
            "Cadastros.Colaborador.Manipular",
            "Cadastros.Colaborador.Visualizar",
            "Cadastros.Colaborador.Editar",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/colaboradores/Form.vue"
          ),
      },
      {
        path: "colaboradores/new",
        name: "colaboradores-create",
        meta: {
          requiredPermissions: [
            "Cadastros.Colaborador.Manipular",
            "Cadastros.Colaborador.Inserir",
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "axios-edit" */ "@/view/cadastros/colaboradores/Form.vue"
          ),
      },

    ],
  },
];

export default routes;
